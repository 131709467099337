import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import "./stylesheet.css";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { useEffect, useState, lazy, Suspense, useMemo } from "react";
import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  createHttpLink,
} from "@apollo/client";
import type { NormalizedCacheObject } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import * as Sentry from "@sentry/react";
import RequireAuth from "./components/RequireAuth";
import { useAuth } from "react-oidc-context";
import { Box, CircularProgress } from "@mui/material";

const Home = lazy(() => import("./pages/Home"));
const NotesPage = lazy(() => import("./pages/Notes"));
const MetricsPage = lazy(() => import("./pages/Metrics"));
const PropertiesPage = lazy(() => import("./pages/PropertiesPage"));
const ManageAccessPage = lazy(() => import("./pages/ManageAccess"));
const InspectionsPage = lazy(() => import("./pages/Inspections"));
const Configuration = lazy(() => import("./pages/Configuration"));
const ManageAssets = lazy(() => import("./pages/EditAssets"));
const AssetsTable = lazy(() => import("./pages/AssetsTable"));
const Login = lazy(() => import("./pages/Login"));
const BatchEntryPage = lazy(() => import("./pages/BatchEntry"));

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  enabled: import.meta.env.PROD,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const fallback = (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
    <CircularProgress />
  </Box>
);

export default function App() {
  const auth = useAuth();

  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();

  const authLink = useMemo(
    () =>
      setContext(async () => {
        const user = !auth.user?.expired
          ? auth.user
          : await auth.signinSilent();
        return {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        };
      }),
    [auth],
  );

  useEffect(() => {
    async function setApolloClientWithAuthLink() {
      const cache = new InMemoryCache();
      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        maxSize: false,
      });

      const httpLink = createHttpLink({
        uri: "/graphql",
        headers: { "Accept-Encoding": "br;q=1.0, gzip;q=0.8, *;q=0.1" },
      });
      setApolloClient(
        new ApolloClient({
          cache,
          link: authLink.concat(httpLink),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: "cache-and-network",
            },
          },
        }),
      );
    }
    void setApolloClientWithAuthLink();
  }, [auth.user, authLink]);

  if (!apolloClient) return fallback;

  const router = sentryCreateBrowserRouter([
    { path: "/", element: <Navigate to="/home" /> },
    {
      path: "/home",
      element: (
        <RequireAuth>
          <Home />
        </RequireAuth>
      ),
    },
    {
      path: "/manage-assets",
      element: (
        <RequireAuth>
          <ManageAssets />
        </RequireAuth>
      ),
    },
    {
      path: "/assets-table",
      element: (
        <RequireAuth>
          <AssetsTable />
        </RequireAuth>
      ),
    },
    {
      path: "/metrics",
      element: (
        <RequireAuth>
          <MetricsPage />
        </RequireAuth>
      ),
    },
    {
      path: "/configuration",
      element: (
        <RequireAuth>
          <Configuration />
        </RequireAuth>
      ),
    },
    {
      path: "/batch-entry",
      element: (
        <RequireAuth>
          <BatchEntryPage />
        </RequireAuth>
      ),
    },
    {
      path: "/home/inspections",
      element: (
        <RequireAuth>
          <InspectionsPage />
        </RequireAuth>
      ),
    },
    {
      path: "/home/notes",
      element: (
        <RequireAuth>
          <NotesPage />
        </RequireAuth>
      ),
    },
    {
      path: "/home/properties",
      element: (
        <RequireAuth>
          <PropertiesPage />
        </RequireAuth>
      ),
    },
    {
      path: "/manage-access",
      element: (
        <RequireAuth>
          <ManageAccessPage />
        </RequireAuth>
      ),
    },
    {
      path: "/login",
      element: <Login />,
    },
  ]);

  return (
    <ApolloProvider client={apolloClient}>
      <Suspense fallback={fallback}>
        <RouterProvider router={router} />
      </Suspense>
    </ApolloProvider>
  );
}
