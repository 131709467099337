import { yellow, red, orange, green } from "@mui/material/colors";
import type { PaletteColorOptions, PaletteColor } from "@mui/material";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    veryGood: PaletteColorOptions;
    good: PaletteColorOptions;
    poor: PaletteColorOptions;
    veryPoor: PaletteColorOptions;
  }
  interface Palette {
    veryGood: PaletteColor;
    good: PaletteColor;
    poor: PaletteColor;
    veryPoor: PaletteColor;
  }
}

const theme = createTheme({
  palette: {
    veryGood: {
      main: green[500],
      contrastText: "#fff",
    },
    good: {
      main: yellow[700],
      contrastText: "#000",
    },
    poor: {
      main: orange[500],
      contrastText: "#000",
    },
    veryPoor: {
      main: red[500],
      contrastText: "#fff",
    },
    primary: {
      main: "#023C40",
    },
    secondary: {
      main: "#C0E8F9",
    },
    background: {
      default: "#E5E5E5",
    },
  },
  components: {
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      },
    },
  },
});

export default theme;
