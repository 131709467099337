/**
 * @file Provides a mapping between a route and the roles that are allowed to access that route
 */
const ADMIN = "Admin";
const JUNIOR_ENGINEER = "Junior Engineer";
const SENIOR_ENGINEER = "Senior Engineer";
const CONTRACTOR = "Contractor";

const homeRoles = new Set([
  ADMIN,
  CONTRACTOR,
  JUNIOR_ENGINEER,
  SENIOR_ENGINEER,
]);

export const authorisedRouteRoles: Record<string, Set<string>> = {
  "/manage-assets": new Set([ADMIN, JUNIOR_ENGINEER, SENIOR_ENGINEER]),
  "/home": homeRoles,
  "/": homeRoles,
  "/assets-table": new Set([
    ADMIN,
    SENIOR_ENGINEER,
    JUNIOR_ENGINEER,
    CONTRACTOR,
  ]),
  "/metrics": new Set([ADMIN, SENIOR_ENGINEER]),
  "/configuration": new Set([ADMIN, SENIOR_ENGINEER]),
  "/manage-access": new Set([ADMIN]),
  "/home/properties": homeRoles,
  "/home/inspections": homeRoles,
  "/home/notes": homeRoles,
  "/batch-entry": new Set([
    ADMIN,
    CONTRACTOR,
    JUNIOR_ENGINEER,
    SENIOR_ENGINEER,
  ]),
};
